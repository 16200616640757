import {
  faArrowLeft,
  faCheck,
  faCircleDown,
  faCircleLeft,
  faCircleRight,
  faDownload,
  faPlusCircle,
  faSignOutAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../assets/calender.jpg";
import "./dashboard.css";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useReactToPrint } from "react-to-print";
import ExpenseExcelComponent from "./expenseDataExcel";
import PaymentExcelComponent from "./paymentDataExcel";
const SiteDetails = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate(`/dashboard`);
  };
  const [Expenseshow, setExpenseShow] = useState(false);
  const [toolshow, setToolshow] = useState(false);

  const ExpenseShow = () => setExpenseShow(true);
  const ToolShow = () => setToolshow(true);
  const ToolClose = () => {
    settoolError("");
    settoolsiteError("");
    setToolshow(false);
  };

  const ExpenseClose = () => {
    setExsiteError("");
    setExdateError("");
    setExcatError("");
    setExdescError("");
    setExamountError("");
    setExpenseShow(false);
  };
  const PaymentClose = () => {
    setPaysiteError("");
    setPaydateError("");
    setPaycatError("");
    setPaydescError("");
    setPayamountError("");
    setPaymentAddModal(false);
  };



  //expense data //
  const [expensesite, setExsite] = useState("");
  const [expensedata, setExdate] = useState("");
  const [expensecategery, setExCategery] = useState("");
  const [expensedescription, setExdesc] = useState("");
  const [expenseamount, setExamount] = useState("");

  const [ExsiteError, setExsiteError] = useState("");
  const [ExdateError, setExdateError] = useState("");
  const [ExcatError, setExcatError] = useState("");
  const [ExdescError, setExdescError] = useState("");
  const [ExamountError, setExamountError] = useState("");

  const handleExpenseChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "expensesite") setExsite(value);
    if (name === "expensedata") setExdate(value);
    if (name === "expensecategery") setExCategery(value);
    if (name === "expensedescription") {
      setExdesc(value);
    }
    // if (name === "expensedescription") setExdesc(value);
    if (name === "expenseamount") {
      setExamount(value);
    }
  };
  const [SiteOverallEx, setOverallEx] = useState<number>(0);

  const [SiteOverallBal, setOverallBal] = useState("");
  const [SiteName, setSiteName] = useState("");
  const [SiteDesc, setSiteDesc] = useState("");
  const [ExpenseTable, setExpenseData] = useState([]);
  const [PaymentTable, setPaymentData] = useState([]);
  const [ExPayHistorydata, setExPayHistory] = useState([]);


  const [state, setState] = useState({
    SiteOptionselect: [],
    ToolOptionselect: [],
    siteShowdetails: [],
    SiteSelect:[],
  });

  useEffect(() => {
    getProjectDetails();
  }, []);

  useEffect(() => {
    console.log("siteShowdetails", state.siteShowdetails);
  }, [state.siteShowdetails]);

  const getProjectDetails = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId: any = searchParams.get("id");
    setExsite(projectId);
    setPaysite(projectId);
    setToolSite(projectId);
    let req = {
      id: projectId,
    };
    callApi("POST", "site_category_exp.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response;
          setState((prevState) => ({
            ...prevState,
            siteShowdetails: res.responseJson,
          }));
          setOverallEx(res.total_amt[0].total_expense);
          setOverallBal(res.total_amt[0].total_balance);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "site_desc_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response;
          setSiteName(res.responseJson.data[0].name);
          setSiteDesc(res.responseJson.data[0].description);
          setEstimation(res.responseJson.data[0].estimation);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "site_exp_pay_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response;
          console.log("site--EXPENCEPAYMENT---", res.responseJson);
          setExpenseData(res.responseJson.Expense);
          setPaymentData(res.responseJson.PAYMENT);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [siteengineerDetails, setsiteengineerDetails] = useState({
    id: "",
    worktype: "",
  });
  useEffect(() => {
    getSiteengineer();
    console.log("siteengineerDetails:", siteengineerDetails);
  }, [siteengineerDetails.worktype]);

  const getSiteengineer = () => {
    callApi("POST", "site_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          setState((prevState) => ({
            ...prevState,
            SiteOptionselect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    callApi("POST", "tool_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          setState((prevState) => ({
            ...prevState,
            ToolOptionselect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

      callApi("POST", "categery_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          setState((prevState) => ({
            ...prevState,
            SiteSelect: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickExpense = () => {
    let ExsiteError = "";
    let ExdateError = "";
    let ExcatError = "";
    let ExdescError = "";
    let ExamountError = "";
    if (!expensesite) {
      ExsiteError = "Site name is required";
    }
    if (!expensedata) {
      ExdateError = "Date is required";
    }
    if (!expensecategery) {
      ExcatError = "Category is required";
    }
    if (!expensedescription) {
      ExdescError = "Description is required";
    }
    if (!expenseamount) {
      ExamountError = "Amount is required";
    }
    if (
      ExsiteError ||
      ExdateError ||
      ExcatError ||
      ExdescError ||
      ExamountError
    ) {
      setExsiteError(ExsiteError);
      setExdateError(ExdateError);
      setExcatError(ExcatError);
      setExdescError(ExdescError);
      setExamountError(ExamountError);
      return;
    }
    const req = {
      site: expensesite,
      date: expensedata,
      categery: expensecategery,
      description: expensedescription,
      amount: expenseamount,
    };
    callApi("POST", "expense_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Added Successfully!");
          setExsite("");
          setExdate("");
          setExCategery("");
          setExdesc("");
          setExamount("");
          setExpenseShow(false);
          getProjectDetails();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickExpenseNew = () => {
    let ExsiteError = "";
    let ExdateError = "";
    let ExcatError = "";
    let ExdescError = "";
    let ExamountError = "";
    if (!expensesite) {
      ExsiteError = "Site name is required";
    }
    if (!expensedata) {
      ExdateError = "Date is required";
    }
    if (!expensecategery) {
      ExcatError = "Category is required";
    }
    if (!expensedescription) {
      ExdescError = "Description is required";
    }
    if (!expenseamount) {
      ExamountError = "Amount is required";
    }
    if (
      ExsiteError ||
      ExdateError ||
      ExcatError ||
      ExdescError ||
      ExamountError
    ) {
      setExsiteError(ExsiteError);
      setExdateError(ExdateError);
      setExcatError(ExcatError);
      setExdescError(ExdescError);
      setExamountError(ExamountError);
      return;
    }
    const req = {
      site: expensesite,
      date: expensedata,
      categery: expensecategery,
      description: expensedescription,
      amount: expenseamount,
    };
    callApi("POST", "expense_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Added Successfully!");
          setExsite("");
          setExdate("");
          setExCategery("");
          setExdesc("");
          setExamount("");
          setExpenseShow(true);
          getProjectDetails();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //payment setvalue data //
  const [paymentsite, setPaysite] = useState("");
  const [paydata, setPaydate] = useState("");
  const [paycategery, setPayCategery] = useState("");
  const [ExtoBalAmt, setExBalAmt] = useState("");
  const [EditExPayMentBal,setExPayMentBal] = useState("");
  const [ExPayAmt, setExPayAmount]  = useState("");
  const [ExpId, setExpId]= useState("");
  const [ExPayHyId ,setExPayHisId] = useState("");

  const [paydescription, setPaydesc] = useState("");
  const [payamount, setPayamount] = useState("");


  const [PaysiteError, setPaysiteError] = useState("");
  const [PaydateError, setPaydateError] = useState("");
  const [PaycatError, setPaycatError] = useState("");
  const [PaydescError, setPaydescError] = useState("");
  const [PayamountError, setPayamountError] = useState("");
  const [ExtraPayamountError, setExtraPayamountError] = useState("");

  const handlePaymentChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "paymentsite") setPaysite(value);
    if (name === "paydata") setPaydate(value);
    if (name === "paycategery") setPayCategery(value);
    if (name === "paydescription") { setPaydesc(value);}
    if (name === "payamount") { 
      if (parseInt(value) > parseInt(ExtoBalAmt)) {
        setPayamountError("Please enter an amount less than the balance");
      } else {
        setPayamountError(""); 
        setPayamount(value);
      }
    }
  };

  const handleClickPayment = () => {
    let PaysiteError = "";
    let PaydateError = "";
    let PaycatError = "";
    let PaydescError = "";
    let PayamountError = "";

    if (!paymentsite) {
      PaysiteError = "Site name is required";
    }
    if (!paydata) {
      PaydateError = "Date is required";
    }
    if (!paycategery) {
      PaycatError = "Category is required";
    }
    if (!paydescription) {
      PaydescError = "Description is required";
    }
    if (!payamount) {
      PayamountError = "Amount is required";
    }
    if (
      PaysiteError ||
      PaydateError ||
      PaycatError ||
      PaydescError ||
      PayamountError
    ) {
      setPaysiteError(PaysiteError);
      setPaydateError(PaydateError);
      setPaycatError(PaycatError);
      setPaydescError(PaydescError);
      setPayamountError(PayamountError);
      return;
    }
    const req = {
      paysite: paymentsite,
      paydate: paydata,
      paycategery: paycategery,
      paydescription: paydescription,
      payamount: payamount,
      ExpId:ExpId,
    };
console.log("requestpayment",req);
    callApi("POST", "payment_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Payment Added Successfully!");
          setPaysite("");
          setPaydate("");
          setPayCategery("");
          setPaydesc("");
          setPayamount("");
          getProjectDetails();
          getSiteengineer();
          setPaymentAddModal(false);
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [EditExpenseshow, setEditExpenseShow] = useState(false);
  const [AddPaymentModal, setPaymentAddModal] = useState(false);
  const [payHistory,setPaymentHistoryModal] = useState(false);

  const PaymentAddshow = (id:any,category:any,bal_amt:any) =>{
    setPaymentAddModal(true);
    setPayCategery(category);
    setExBalAmt(bal_amt);
    setExpId(id);
    console.log("paycategery",paycategery);   
  }

  const ExPayHistory = (id:any) =>{
    setExPayHisId(id);
    const searchParams = new URLSearchParams(window.location.search);
    const projectId: any = searchParams.get("id");
    let req = {
      id: projectId,
      Expid:id
    };
    console.log("req",req);
    callApi("POST", "expayment_history.php", req)
    .then((res) => res.data)
    .then((response) => {
      if (response["statusCode"] == 200) {
        let res = response;
        setExPayHistory(res.responseJson.PAYMENT);
        setPaymentHistoryModal(true);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const PayHistoryClose = () =>{
    setPaymentHistoryModal(false);
    setExtraPayamountError("");
  }

  const [tool, setTool] = useState("");
  const [toolsite, setToolSite] = useState("");
  const [toolqty, setToolqty] = useState("");
  const [ToolError, settoolError] = useState("");
  const [ToolSiteError, settoolsiteError] = useState("");
  const [ToolqtyError, settoolqtyError] = useState("");

  const handleToolChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "tool") setTool(value);
    if (name === "toolsite") setToolSite(value);
    if (name === "toolqty") {
      setToolqty(value);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "SiteDesc") setSiteDesc(value);
  };

  const handleBlurDesc = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    let req = {
      id: projectId,
      SiteDesc: SiteDesc,
    };

    callApi("POST", "site_desc _update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setSiteDesc(res.responseJson.data[0].SiteDesc);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const handleKeyDownDesc = (event: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (event.key === "Enter") {
      let req = {
        id: projectId,
        SiteDesc: SiteDesc,
      };

      callApi("POST", "site_desc _update.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["statusCode"] == 200) {
            let res = response.responseJson.data[0];
            setSiteDesc(res.responseJson.data[0].SiteDesc);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const handleClickTool = () => {
    let ToolError = "";
    let ToolSiteError = "";
    let ToolqtyError = "";
    if (!tool) {
      ToolError = "Tool is required";
    }
    if (!toolsite) {
      ToolSiteError = "Site is required";
    }
    if (!toolqty) {
      ToolqtyError = "Quantity is required";
    }
    if (ToolError || ToolSiteError || ToolqtyError) {
      settoolError(ToolError);
      settoolsiteError(ToolSiteError);
      settoolqtyError(ToolqtyError);
      return;
    }
    const req = {
      tool: tool,
      site: toolsite,
      qty: toolqty,
    };

    callApi("POST", "site_toolinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          setToolshow(false);
          setTool("");
          setToolSite("");
          setToolqty("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClickToolNew = () => {
    let ToolError = "";
    let ToolSiteError = "";
    let ToolqtyError = "";
    if (!tool) {
      ToolError = "Tool is required";
    }
    if (!toolsite) {
      ToolSiteError = "Site is required";
    }
    if (!toolqty) {
      ToolqtyError = "Quantity is required";
    }
    if (ToolError || ToolSiteError || ToolqtyError) {
      settoolError(ToolError);
      settoolsiteError(ToolSiteError);
      settoolqtyError(ToolqtyError);
      return;
    }
    const req = {
      tool: tool,
      site: toolsite,
      qty: toolqty,
    };

    callApi("POST", "site_toolinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          setToolshow(true);
          setTool("");
          setToolSite("");
          setToolqty("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //filter data//
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const toDateRef = useRef<HTMLDivElement | null>(null);
  const fromDateRef = useRef<HTMLDivElement | null>(null);
  const [showToDateCalendar, setShowToDateCalendar] = useState(false);
  const [showFromDateCalendar, setShowFromDateCalendar] = useState(false);
  const [FromDateError, setFromDateError] = useState("");
  const [ToDateError, setToDateError] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        toDateRef.current &&
        !(toDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowToDateCalendar(false);
      }
      if (
        fromDateRef.current &&
        !(fromDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowFromDateCalendar(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const printRef = useRef<HTMLDivElement | null>(null);

  const downloadPDF = useReactToPrint({
    content: () => (printRef.current ? printRef.current : null),
  });

  const confirmDeleteAndDownloadPDF = () => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      alertdownloadPDF();
    }
  };
  const deleteprojectfun = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    let req = {
      Prjid: projectId,
    };
    try {
      const response = await callApi("POST", "project_delete.php", req);
      showSuccess("Success!", "Project Deleted Successfully!");
      navigate("/dashboard");
    } catch (err) {
      console.log("Error:", err);
    }
  };
  const alertdownloadPDF = useReactToPrint({
    content: () => (printRef.current ? printRef.current : null),
    onAfterPrint: deleteprojectfun,
  });
  const EditExpenseClose = () => {
    setEditExpenseShow(false);
  };
  const [EditExpenseDetails, setExpenseeditData] = useState({
    id: "",
    prj_idfk: "",
    date: "",
    category: "",
    Desc: "",
    Amount: "",
  });

  const Editonchange = (e: any) => {
    const { name, value } = e.target;
    if (name === "prj_idfk") {
      setExpenseeditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "date") {
      setExpenseeditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "category") {
      setExpenseeditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "Desc") {
      setExpenseeditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "Amount") {
      setExpenseeditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const ExEditModalshow = (id: any) => {
    setEditExpenseShow(true);
    let req = {
      ExId: id,
    };
    callApi("POST", "site_exp_edit_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setExpenseeditData((prevState) => ({
            ...prevState,
            id: res.id,
            prj_idfk: res.prj_idfk,
            date: res.date,
            category: res.category,
            Desc: res.Desc,
            Amount: res.Amount,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateExpence = (id: any) => {
    let req = {
      ExId: id,
      ExsiteName: EditExpenseDetails.prj_idfk,
      Exdate: EditExpenseDetails.date,
      ExCat: EditExpenseDetails.category,
      ExDesc: EditExpenseDetails.Desc,
      ExAmount: EditExpenseDetails.Amount,
    };
    callApi("POST", "site_exp_edit.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Expense Updated Successfully!");
          getProjectDetails();
          setEditExpenseShow(false);
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Expensedelete = (id: any) => {
    const confirmLogout = window.confirm("Are you sure you want to delete?");
    if (confirmLogout) {
      let req = {
        ExId: id,
      };
      callApi("POST", "site_exp_delete.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["success"] === true) {
            showSuccess("Success!", "Expense Deleted Successfully!");
            getProjectDetails();
          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const ExpenseDontdelete =() => {
    const confirmLogout = window.confirm("Unable to delete this expense! - Payments are linked to this expense - Delete the associated payments to proceed with the deletion.");
  }

  const [EditPaymentshow, setEditPaymentshow] = useState(false);
  const [EditHistoryPaymentshow, setEditHistoryPaymentshow] = useState(false);

  
  const EditPaymentClose = () => {
    setEditPaymentshow(false);
    setExtraPayamountError("");

  };
  const EditHistoryPaymentClose = () => {
    setEditHistoryPaymentshow(false);
    setExtraPayamountError("");
  }; 
  const [EditPaymentDetails, setPaymenteditData] = useState({
    id: "",
    prj_idfk: "",
    date: "",
    category: "",
    Desc: "",
    Amount: "",
  });

  const EditPayonchange = (e: any) => {
    const { name, value } = e.target;
    if (name === "prj_idfk") {
      setPaymenteditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "date") {
      setPaymenteditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "category") {
      setPaymenteditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "Desc") {
      setPaymenteditData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "Amount") {
      console.log("EditPaymentDetails.Amount",ExPayAmt);
      console.log("EditExPayMentBal",EditExPayMentBal);
      let TotalExPay;
      TotalExPay=(parseInt(ExPayAmt) + parseInt(EditExPayMentBal));
      console.log("TotalExPay",TotalExPay);
      if (parseInt(value) > TotalExPay) {
        setExtraPayamountError("Please enter an amount less than the balance");
      } else {
        setExtraPayamountError(""); 
        setPaymenteditData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }

   
  };

  const PayEditModalshow = (id: any) => {
    setEditPaymentshow(true);
    let req = {
      PayId: id,
    };
    callApi("POST", "site_payment_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setExPayMentBal(res.amt);
          setExPayAmount(res.Amount);
          setPaymenteditData((prevState) => ({
            ...prevState,
            id: res.id,
            prj_idfk: res.prj_idfk,
            date: res.date,
            category: res.category,
            Desc: res.Desc,
            Amount: res.Amount,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HistoryPayEditModalshow  = (id: any) => {
    setEditHistoryPaymentshow(true);
    let req = {
      PayId: id,
    };
    callApi("POST", "site_payment_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setPaymenteditData((prevState) => ({
            ...prevState,
            id: res.id,
            prj_idfk: res.prj_idfk,
            date: res.date,
            category: res.category,
            Desc: res.Desc,
            Amount: res.Amount,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdatePayment = (id: any) => {
    let req = {
      PayId: id,
      PaysiteName: EditPaymentDetails.prj_idfk,
      Paydate: EditPaymentDetails.date,
      PayCat: EditPaymentDetails.category,
      PayDesc: EditPaymentDetails.Desc,
      PayAmount: EditPaymentDetails.Amount,
    };
    callApi("POST", "site_payment_edit.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Payment Updated Successfully!");
          getProjectDetails();
          setEditPaymentshow(false);
          // ExPayHistory(ExPayHyId);
          //  console.log("jaisurya============");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const HistoryUpdatePayment = (id: any) => {
    let req = {
      PayId: id,
      PaysiteName: EditPaymentDetails.prj_idfk,
      Paydate: EditPaymentDetails.date,
      PayCat: EditPaymentDetails.category,
      PayDesc: EditPaymentDetails.Desc,
      PayAmount: EditPaymentDetails.Amount,
    };
    callApi("POST", "site_payment_edit.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Payment Updated Successfully!");
          getProjectDetails();
          setEditPaymentshow(false);
          setEditHistoryPaymentshow(false);
          ExPayHistory(ExPayHyId);
           console.log("jaisurya============");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Paymentdelete = (id: any) => {
    const confirmLogout = window.confirm("Are you sure you want to delete?");
    if (confirmLogout) {
      let req = {
        PayId: id,
      };
      callApi("POST", "site_payment_delete.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["success"] === true) {
            showSuccess("Success!", "Payment Deleted Successfully!");
            getProjectDetails();
          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const HistoryPaymentdelete = (id: any) => {
    const confirmLogout = window.confirm("Are you sure you want to delete?");
    if (confirmLogout) {
      let req = {
        PayId: id,
      };
      callApi("POST", "site_payment_delete.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["success"] === true) {
            showSuccess("Success!", "Payment Deleted Successfully!");
            getProjectDetails();
          ExPayHistory(ExPayHyId);

          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [AddToolModal, setAddToolModal] = useState(false);
  const AddToolshow = () => {
    setAddToolModal(true);
    setToolshow(false);
  };
  const [adtool, setAdTool] = useState("");
  const [adtoolerror, setADDToolError] = useState("");

  const AddtoolModalClose = () => {
    setAddToolModal(false);
    setToolshow(true);
  };

  const handleAddTOOLChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "tool") {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setAdTool(value);
        if (value.length < 5 || value.length > 50) {
          setADDToolError("Tool name must be between 5 and 50 characters.");
        } else {
          setADDToolError("");
        }
      }
    }
  };

  const AddhandleClick = () => {
    let AddtoolError = "";
    if (!adtool) {
      AddtoolError = "Tool is required";
    }
    if (AddtoolError) {
      setADDToolError(AddtoolError);
      return;
    }
    const req = {
      tool: adtool,
    };
    callApi("POST", "tool_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          getSiteengineer();
          setAddToolModal(false);
          setToolshow(true);
          setAdTool("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddhandleClickNew = () => {
    let AddtoolError = "";
    if (!adtool) {
      AddtoolError = "Tool is required";
    }
    if (AddtoolError) {
      setADDToolError(AddtoolError);
      return;
    }
    const req = {
      tool: adtool,
    };
    callApi("POST", "tool_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          setAddToolModal(true);
          getSiteengineer();
          setAdTool("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [fromdate, setFROMdate] = useState("");
  const [todate, setTOdate] = useState("");
  const DatesearchChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "fromdate") setFROMdate(value);
    if (name === "todate") setTOdate(value);
  };

  const fillterdata = () => {
    let FromDateError = "";
    let ToDateError = "";
    if (!fromdate) {
      FromDateError = "FromDate is required";
    }
    if (!todate) {
      ToDateError = "ToDate is required";
    }
    if (FromDateError || ToDateError) {
      setFromDateError(FromDateError);
      setToDateError(ToDateError);
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");

    let formattedFromDate = fromDate.split("/").reverse().join("-");
    let formattedToDate = toDate.split("/").reverse().join("-");

    let req = {
      id: projectId,
      fromdate: fromdate,
      todate: todate,
    };
    callApi("POST", "site_exp_pay_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response;
          showSuccess("Success!", "Filter Data Showing Expense Payment Table!");
          setExpenseData(res.responseJson.Expense);
          setPaymentData(res.responseJson.PAYMENT);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [Estimation, setEstimation] = useState<number>(0);

  const handleEstimateChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "Estimation") setEstimation(value);
  };

  const BlurEstimation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    let req = {
      id: projectId,
      Estimation: Estimation,
    };
    callApi("POST", "site_estimate_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setEstimation(res.responseJson.data[0].Estimation);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const KeyDownEstimation = (event: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (event.key === "Enter") {
      let req = {
        id: projectId,
        Estimation: Estimation,
      };
callApi("POST", "site_estimate_update.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["statusCode"] == 200) {
            let res = response.responseJson.data[0];
            setEstimation(res.responseJson.data[0].Estimation);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
        
    }
  };
  let AvailableBal =0;
if(Estimation !=0){
 AvailableBal = Estimation - SiteOverallEx

}
  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faCircleLeft}
            className="icon backicon"
            onClick={back}
          />
          <h2 className="heading" style={{textAlign:"center"}}>{SiteName}</h2>
          <div>
            <FontAwesomeIcon
              icon={faDownload}
              className="icon printicon"
              onClick={downloadPDF}
            />
          </div>
        </div>
      </header>
      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="2" md="2" className="flexbtn">
            <Button variant="outline-primary subbutton" onClick={ExpenseShow} style={{color:" #fff !important"}}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Expense
            </Button>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
            <Button
              variant="outline-primary subbutton mobviewbtn"
              onClick={ToolShow}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Tool
            </Button>
          </Col>
          <Col lg="2" md="2" className="flexbtn">
          
          </Col>
          <Col lg="1" md="1" className="flexbtn"></Col>
          <Col lg="2" md="2" className="flexbtn1" style={{ marginTop: "2%" }}>
            <div className="labeldiv">
              <label className="label">From Date*</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Description"
                name="fromdate"
                minLength={5}
                maxLength={100}
                required
                value={fromdate}
                onChange={DatesearchChange}
                onFocus={() => setFromDateError("")}
              />
              <div className="text-danger">{FromDateError}</div>
            </div>
          </Col>

          <Col lg="2" md="2" className="flexbtn1" style={{ marginTop: "2%" }}>
            <div className="labeldiv">
              <label className="label">To Date*</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter todate"
                name="todate"
                minLength={5}
                maxLength={100}
                required
                value={todate}
                onChange={DatesearchChange}
                onFocus={() => setToDateError("")}
              />
              <div className="text-danger">{ToDateError}</div>
            </div>
          </Col>

          <Col lg="1" md="1" className="flexbtn2 fildercol">
            <div className="labeldiv">
              <label className="label"></label>
              <FontAwesomeIcon
                icon={faCircleRight}
                className="icon"
                style={{ fontSize: "32px" }}
                onClick={fillterdata}
              />
            </div>
          </Col>
        </Row>

        <Row style={{ padding: "0% 3%" }} className="scrollRow">
        <Col lg="3" md="3" className="" style={{ marginTop: "2%" }}>
            <div className="labeldiv">
              <label className="label card-title1">Estimation :</label>
              <input
                type="text"
                value={Estimation}
                name="Estimation"
                onChange={handleEstimateChange}
                placeholder="Enter Estimation"
                className="comments"
                onBlur={BlurEstimation}
                onKeyDown={KeyDownEstimation}
                style={{fontWeight:"600",fontSize:"20px"}}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{FromDateError}</div>
            </div>
          </Col>

          <Col lg="3" md="3" className="" style={{ marginTop: "2%" }}>
            <div className="labeldiv">
            <label className="label card-title1">Available Balance :</label>
              <input
                type="number"
                value={AvailableBal}
                name="Estimation"
                onChange={handleEstimateChange}
                className="comments"
                style={{fontWeight:"700",fontSize:"20px"}}
               readOnly
              />
         
            </div>
          </Col>

          <Col lg="6" md="6" className="" style={{ marginTop: "2%" }}>
            <div className="labeldiv">
              <label className="label card-title1">Comments :</label>
              <textarea
                    value={SiteDesc}
                    name="SiteDesc"
                    onChange={handleChange}
                    placeholder="Enter Text here..."
                    className="comments"
                    onBlur={handleBlurDesc}
                    onKeyDown={handleKeyDownDesc}
                  />
            </div>
          </Col>

              </Row>

        <Row style={{ padding: "0% 3%" }} className="scrollRow">
            <Col lg="3" md="3" className="flex">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Overall Expense</h5>
                  <div className="flexamount">
                    <p className="card-text">Rs.{SiteOverallEx || 0}</p>
                    <p className="card-text">Bal=Rs.{SiteOverallBal || 0}</p>
                  </div>
                </div>
              </div>
            </Col>
            {state.siteShowdetails &&
              state.siteShowdetails.length > 0 &&
              state.siteShowdetails.map((project: any, index) => {
                return (
                  <Col lg="3" md="3" className="flex">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">{project.category}</h5>
                        <div className="flexamount">
                          <p className="card-text">Rs.{project.expense}</p>
                          <p className="card-text">
                            Bal=Rs.{project.balance_payment}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

        {/* //print section // */}
        <div ref={printRef}>
          <header className="print-content">
            <div className="">
              <h1 className="heading">{SiteName}</h1>
            </div>
          </header>
          <br />
          <Row style={{ padding: "0% 3%" }} className="scrollRow print-content ">
            <div className="grid-container">
            <div className="flex grid-item">
              <div className="card" style={{width:"100% !important"}}>
                <div className="card-body">
                  <h5 className="card-title">Overall Expense</h5>
                  <div className="flexamount">
                    <p className="card-text">Rs.{SiteOverallEx || 0}</p>
                    <p className="card-text">Bal=Rs.{SiteOverallBal || 0}</p>
                  </div>
                </div>
              </div>
            </div>
            {state.siteShowdetails &&
              state.siteShowdetails.length > 0 &&
              state.siteShowdetails.map((project: any, index) => {
                return (
                  <div  className="flex grid-item">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="card-title">{project.category}</h5>
                        <div className="flexamount">
                          <p className="card-text">Rs.{project.expense}</p>
                          <p className="card-text">
                            Bal=Rs.{project.balance_payment}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              </div>
            <Col lg="3" md="3" className="flex">
              <Row>
                <Col lg="12" md="12">
                  <label style={{ marginBottom: "3%" }}>Comments:</label>
                </Col>
                <br />
                <Col lg="12" md="12">
                  <textarea
                    value={SiteDesc}
                    name="SiteDesc"
                    onChange={handleChange}
                    placeholder="Enter Text here..."
                    className="comments"
                    onBlur={handleBlurDesc}
                    onKeyDown={handleKeyDownDesc}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ padding: "0% 3%" }} className="scrollRow">
            <Col lg="6" md="6" className="">
              <Row>
                <Col lg="12" md="12">
                  {ExpenseTable.length > 0 && (
                    <>
                      <h3 className="h3h3h3" style={{ fontSize: "2rem",fontWeight: "bold",color: "#333",textAlign: "center",textTransform: "uppercase",borderBottom: "2px solid #333",paddingBottom: "0.5rem",
                        }}>
                        {" "}
                        Expenses     
              <ExpenseExcelComponent data={ExpenseTable} />
                      </h3>
                    </>
                  )}
                </Col>
                <Col lg="12" md="12" className="">
                  <Table
                    responsive="md lg"
                    striped
                    bordered
                    hover
                    style={{ width: "100% !important", textAlign: "center" }}
                  >
                    {ExpenseTable.length > 0 && (
                      <>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Balance</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ExpenseTable.map((data: any, index: any) => (
                            <>
                              <tr key={index}>
                                <td onClick={() => ExPayHistory(data.id)}>{index + 1}</td>
                                <td onClick={() => ExPayHistory(data.id)}>{data.date}</td>
                                <td onClick={() => ExPayHistory(data.id)}>{data.category}</td>
                                <td onClick={() => ExPayHistory(data.id)}>{data.Desc}</td>
                                <td onClick={() => ExPayHistory(data.id)}>{data.Amount}</td>
                                <td onClick={() => ExPayHistory(data.id)}>{data.bal_amt}</td>
                                <td>
                                  <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle
                                      id="dropdown-autoclose-true"
                                      className="actionbutton">
                                      Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                    {data.bal_amt =="0" ? (
                                  <Dropdown.Item style={{color:"green"}}>
                                     Paid  <FontAwesomeIcon icon={faCheck} className="icon" />
                               </Dropdown.Item>
                                    ): (
                                      <Dropdown.Item
                                      onClick={() => PaymentAddshow(data.id,data.category,data.bal_amt)}>
                                      Pay
                                    </Dropdown.Item>
                                    )}
                                      <Dropdown.Item
                                        onClick={() => ExEditModalshow(data.id)}>
                                        Edit
                                      </Dropdown.Item>
                                      {data.bal_amt != data.Amount  ? 
                                      (
                                        <Dropdown.Item
                                        onClick={() => ExpenseDontdelete()}>
                                        Delete 
                                      </Dropdown.Item> 
                                      ):(
                                        <Dropdown.Item
                                        onClick={() => Expensedelete(data.id)}>
                                        Delete
                                      </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </>
                    )}
                  </Table>
                </Col>
              </Row>
            </Col>

            <Col lg="6" md="6" className="">
              <Row>
                <Col lg="12" md="12">
                  {PaymentTable.length > 0 && (
                    <>
                      <h3
                        className="h3h3h3"
                        style={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          color: "#333",
                          textAlign: "center",
                          textTransform: "uppercase",
                          borderBottom: "2px solid #333",
                          paddingBottom: "0.5rem",
                        }}
                      >
                        Payments
              <PaymentExcelComponent data={PaymentTable} />

                      </h3>
                    </>
                  )}
                </Col>
                <Col lg="12" md="12" className="">
                  <Table
                    responsive="md lg"
                    striped
                    bordered
                    hover
                    style={{ width: "100% !important", textAlign: "center" }}
                  >
                    {PaymentTable.length > 0 && (
                      <>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {PaymentTable.map((data: any, index: any) => (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.date}</td>
                                <td>{data.category}</td>
                                <td>{data.Desc}</td>
                                <td>{data.Amount}</td>
                                <td>
                                  <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle
                                      id="dropdown-autoclose-true"
                                      className="actionbutton"
                                    >
                                      Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          PayEditModalshow(data.id)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() => Paymentdelete(data.id)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </>
                    )}
                  </Table>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      {/* //footer // */}
      <header>
        <div className="">
          <h3 className="heading" style={{textAlign:"center"}}>
            Delete 
            <FontAwesomeIcon
              icon={faTrash}
              className="icon "
              onClick={confirmDeleteAndDownloadPDF}
            />
          </h3>
        </div>
      </header>

      <Modal show={Expenseshow} onHide={ExpenseClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name* </label>
              <Form.Select
                aria-label="Default select example"
                name="expensesite"
                onChange={handleExpenseChange}
                onFocus={() => setExsiteError("")}
                value={expensesite}
              >
                <option value="">Select Site</option>
                {state.SiteOptionselect.map((categery: any) => (
                  <option key={categery.id} value={categery.id}>
                    {categery.site}
                  </option>
                ))}
              </Form.Select>

              <div className="text-danger">{ExsiteError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Date*</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Date"
                name="expensedata"
                minLength={5}
                maxLength={100}
                required
                onChange={handleExpenseChange}
                onFocus={() => setExdateError("")}
                value={expensedata}
              />
              <div className="text-danger">{ExdateError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Category*</label>
              <Form.Select
                aria-label="Default select example"
                name="expensecategery"
                onChange={handleExpenseChange}
                onFocus={() => setExcatError("")}
                value={expensecategery}
              >
                  <option value="">Select Category</option>
                              {state.SiteSelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.categery}
                                >
                                  {categery.categery}
                                </option>
                              ))}
              </Form.Select>
              <div className="text-danger">{ExcatError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Description*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="expensedescription"
                minLength={5}
                maxLength={100}
                required
                onChange={handleExpenseChange}
                onFocus={() => setExdescError("")}
                value={expensedescription}
              />
              <div className="text-danger">{ExdescError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Amount*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                name="expenseamount"
                minLength={5}
                maxLength={100}
                required
                onChange={handleExpenseChange}
                onFocus={() => setExamountError("")}
                value={expenseamount}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{ExamountError}</div>
            </div>
            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickExpense}
              >
                Save
              </button>

              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickExpenseNew}
              >
                Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={AddPaymentModal} onHide={PaymentClose}>
        <Modal.Header closeButton>
          <Modal.Title>
              <div className="balance-card">
        Balance: <span className="balance">{ExtoBalAmt}</span>
    </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name* </label>
              <Form.Select
                aria-label="Default select example"
                name="paymentsite"
                onChange={handlePaymentChange}
                onFocus={() => setPaysiteError("")}
                value={paymentsite}
                disabled 
                // readOnly={true}
              >
                <option value="">Select Site</option>
                {state.SiteOptionselect.map((categery: any) => (
                  <option key={categery.id} value={categery.id}>
                    {categery.site}
                  </option>
                ))}
              </Form.Select>
              <div className="text-danger">{PaysiteError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Date*</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Description"
                name="paydata"
                minLength={5}
                maxLength={100}
                required
                onChange={handlePaymentChange}
                onFocus={() => setPaydateError("")}
                value={paydata}
              />
              <div className="text-danger">{PaydateError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Category*</label>
              <input
               className="form-control"
                name="paycategery"
                onChange={handlePaymentChange}
                onFocus={() => setPaycatError("")}
                value={paycategery}
                readOnly
              />
              <div className="text-danger">{PaycatError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Description*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="paydescription"
                minLength={5}
                maxLength={100}
                required
                onChange={handlePaymentChange}
                onFocus={() => setPaydescError("")}
                value={paydescription}
              />
              <div className="text-danger">{PaydescError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Amount*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                name="payamount"
                minLength={5}
                maxLength={100}
                required
                onChange={handlePaymentChange}
                onFocus={() => setPayamountError("")}
                value={payamount}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{PayamountError}</div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickPayment}>
                Save
              </button>
             
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={toolshow} onHide={ToolClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name* </label>

              <Form.Select
                aria-label="Default select example"
                name="toolsite"
                onChange={handleToolChange}
                onFocus={() => settoolsiteError("")}
                value={toolsite}
              >
                <option value="">Select Site</option>
                {state.SiteOptionselect.map((categery: any) => (
                  <option key={categery.id} value={categery.id}>
                    {categery.site}
                  </option>
                ))}
              </Form.Select>
              <div className="text-danger">{ToolSiteError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Tool Name*</label>
              <div className="flex">
                <Form.Select
                  aria-label="Default select example"
                  name="tool"
                  onChange={handleToolChange}
                  onFocus={() => settoolError("")}
                  value={tool}
                >
                  <option value="">Select Tool</option>
                  {state.ToolOptionselect.map((categery: any) => (
                    <option key={categery.id} value={categery.tool}>
                      {categery.tool}
                    </option>
                  ))}
                </Form.Select>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="icon plusicon1"
                  onClick={AddToolshow}
                />
              </div>

              <div className="text-danger">{ToolError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Quantity*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Quantity"
                name="toolqty"
                minLength={5}
                maxLength={100}
                required
                onChange={handleToolChange}
                onFocus={() => settoolqtyError("")}
                value={toolqty}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{ToolqtyError}</div>
            </div>
            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickTool}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClickToolNew}
              >
                Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={EditExpenseshow} onHide={EditExpenseClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name</label>
              <Form.Select
                aria-label="Default select example"
                name="prj_idfk"
                onChange={Editonchange}
                value={EditExpenseDetails.prj_idfk}
              >
                <option value="">Select Site</option>
                {state.SiteOptionselect.map((categery: any) => (
                  <option key={categery.id} value={categery.id}>
                    {categery.site}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="labeldiv">
              <label className="label">Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Date"
                name="date"
                minLength={5}
                maxLength={100}
                required
                onChange={Editonchange}
                value={EditExpenseDetails.date}
              />
            </div>
            <div className="labeldiv">
              <label className="label">Category</label>
              <Form.Select
                aria-label="Default select example"
                name="category"
                onChange={Editonchange}
                value={EditExpenseDetails.category}
              >
    <option value="">Select Category</option>
                              {state.SiteSelect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.categery}
                                >
                                  {categery.categery}
                                </option>
                              ))}
              </Form.Select>
            </div>
            <div className="labeldiv">
              <label className="label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="Desc"
                minLength={5}
                maxLength={100}
                required
                onChange={Editonchange}
                value={EditExpenseDetails.Desc}
              />
            </div>
            <div className="labeldiv">
              <label className="label">Amount</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                name="Amount"
                minLength={5}
                maxLength={100}
                required
                onChange={Editonchange}
                value={EditExpenseDetails.Amount}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => UpdateExpence(EditExpenseDetails.id)}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
{/* //suriya payment edit// */}
      <Modal show={EditPaymentshow} onHide={EditPaymentClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name</label>
              <Form.Select
                aria-label="Default select example"
                name="prj_idfk"
                onChange={EditPayonchange}
                value={EditPaymentDetails.prj_idfk}
                disabled 
              >
                <option value="">Select Site</option>
                {state.SiteOptionselect.map((categery: any) => (
                  <option key={categery.id} value={categery.id}>
                    {categery.site}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="labeldiv">
              <label className="label">Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Description"
                name="date"
                minLength={5}
                maxLength={100}
                required
                onChange={EditPayonchange}
                value={EditPaymentDetails.date}
              />
            </div>
            <div className="labeldiv">
              <label className="label">Category</label>
              <input
                  className="form-control"
                name="category"
                onChange={EditPayonchange}
                value={EditPaymentDetails.category}
                readOnly
              />
            </div>
            <div className="labeldiv">
              <label className="label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="Desc"
                minLength={5}
                maxLength={100}
                required
                onChange={EditPayonchange}
                value={EditPaymentDetails.Desc}
              />
            </div>
            <div className="labeldiv">
              <label className="label">Amount</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                name="Amount"
                minLength={5}
                maxLength={100}
                required
                onFocus={() => setExtraPayamountError("")}
                onChange={EditPayonchange}
                value={EditPaymentDetails.Amount}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{ExtraPayamountError}</div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => UpdatePayment(EditPaymentDetails.id)}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* suriyahistory// */}
      <Modal show={EditHistoryPaymentshow} onHide={EditHistoryPaymentClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Site Name</label>
              <Form.Select
                aria-label="Default select example"
                name="prj_idfk"
                onChange={EditPayonchange}
                value={EditPaymentDetails.prj_idfk}
                disabled 
              >
                <option value="">Select Site</option>
                {state.SiteOptionselect.map((categery: any) => (
                  <option key={categery.id} value={categery.id}>
                    {categery.site}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="labeldiv">
              <label className="label">Date</label>
              <input
                type="date"
                className="form-control"
                placeholder="Enter Description"
                name="date"
                minLength={5}
                maxLength={100}
                required
                onChange={EditPayonchange}
                value={EditPaymentDetails.date}
              />
            </div>
            <div className="labeldiv">
              <label className="label">Category</label>
              <input
               className="form-control"
                name="category"
                onChange={EditPayonchange}
                value={EditPaymentDetails.category}
                readOnly
             />
                  
            </div>
            <div className="labeldiv">
              <label className="label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="Desc"
                minLength={5}
                maxLength={100}
                required
                onChange={EditPayonchange}
                value={EditPaymentDetails.Desc}
              />
            </div>
            <div className="labeldiv">
              <label className="label">Amount</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                name="Amount"
                minLength={5}
                maxLength={100}
                onFocus={() => setExtraPayamountError("")}
                required
                onChange={EditPayonchange}
                value={EditPaymentDetails.Amount}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{ExtraPayamountError}</div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => HistoryUpdatePayment(EditPaymentDetails.id)}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={AddToolModal} onHide={AddtoolModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Tool* </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Tool"
                name="tool"
                minLength={5}
                maxLength={50}
                value={adtool}
                required
                onChange={handleAddTOOLChange}
                onFocus={() => setADDToolError("")}
                pattern="^[A-Za-z]+$"
              />
              <div className="text-danger">{adtoolerror}</div>
            </div>

            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={AddhandleClick}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={AddhandleClickNew}
              >
                Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal show={payHistory} onHide={PayHistoryClose} style={{width:"105% !important"}}>
        <Modal.Header closeButton>
          <Modal.Title>
             Payment History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
        <Col lg="12" md="12" className="">
                  <Table
                    responsive="md lg"
                    striped
                    bordered
                    hover
                    style={{ width: "100% !important", textAlign: "center" }}
                  >
                    {ExPayHistorydata.length > 0 ? (
                      <>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ExPayHistorydata.map((data: any, index: any) => (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.date}</td>
                                <td>{data.category}</td>
                                <td>{data.Desc}</td>
                                <td>{data.Amount}</td>
                                <td>
                                  <Dropdown className="d-inline mx-2">
                                    <Dropdown.Toggle
                                      id="dropdown-autoclose-true"
                                      className="actionbutton"
                                    >
                                      Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() =>
                                          HistoryPayEditModalshow(data.id)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        onClick={() => HistoryPaymentdelete(data.id)}
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </>
                    ):(
                      <h5 style={{textAlign:"center"}}>No data found</h5>
                    )}
                  </Table>
                </Col>
       </Row>
        </Modal.Body>
      </Modal>
    </main>
  );
};
export default SiteDetails;
