import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleLeft,
  faGear,
  faPlusCircle,
  faSearch,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";
import "./product.css";


const ToolsManagement = () => {
  const navigate = useNavigate();
  const [ToolModal, setToolModal] = useState(false);
  const [colorselectmodal, setToolselectmodal] = useState(false);
  const [toolsite, setToolSite] = useState("");
  const [toolselect, setToolSelect] = useState("");
  const [toolqty, setToolQty] = useState("");
  const [siteError, setSiteError] = useState("");
  const [toolError, setToolError] = useState("");
  const [qtyError, setqtyError] = useState("");

  const [state, setState] = useState({
    ToolDetailstable: [],
    SiteOptionselect: [],
    ToolOptionselect:[],
  });
  const [selectEditValue, setToolEditvalue] = useState({ id: "", tool: "",site:"",qty:""});
  const handleToolshow = () => setToolModal(true);
  const toolModalClose = () => {
    setToolModal(false);
  };
  const ToolManegeEdit = () => {
    navigate(`/Tooladd`);

  }
  const handleselectEditValueClose = () => {
    setToolselectmodal(false);
    setToolEditvalue({id: "", tool: "",site:"",qty:""});
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "sitename") setToolSite(value);
    if (name === "toolselect") setToolSelect(value);
    if (name === "toolqty"){ setToolQty(value);}
  };

//use effect //
const [siteengineerDetails, setsiteengineerDetails] = useState({
    id: "",
    worktype: "",
  });

  useEffect(() => {
    getSiteengineer();
    console.log("siteengineerDetails:", siteengineerDetails);
  }, [siteengineerDetails.worktype]);

  const getSiteengineer = () => {
    callApi("POST", "site_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("site_select", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            SiteOptionselect: res.data.responseJson.data,
          }));
          console.log("---selectsite",state.SiteOptionselect);
        }
      })
      .catch((err) => {
        console.log(err);
      });

      callApi("POST", "tool_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("toolresponse", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            ToolOptionselect: res.data.responseJson.data,
          }));
          console.log("---ToolOptionselect",state.ToolOptionselect);

        }
      })
      .catch((err) => {
        console.log(err);
      });

  };


  const getProjectDetails = () => {
    callApi("POST", "site_tool_select.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("toolresponse", res.data.responseJson.data);
          setState((prevState) => ({
            ...prevState,
            ToolDetailstable: res.data.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const colorhandleClick = () => {
    let toolError = "";
    let siteError="";
    let qtyError="";

    if (!toolsite) {
        siteError = "Site is required";
    } 
    if (!toolselect) {
        toolError = "Tool is required";
      } 
      if (!toolqty) {
        qtyError = "Quantity is required";
      } 
    if (siteError || toolError ||qtyError) {
        setSiteError(siteError);
      setToolError(toolError);
      setqtyError(qtyError);
      return;
    }
    const req = {
      tool: toolselect,
      site:toolsite,
      qty:toolqty
    };
    callApi("POST", "site_toolinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          setToolModal(false);
          setToolSite("");
          setToolSelect("");
          setToolQty("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("tools:", toolsite);
  }, [toolsite,toolselect,toolqty]);
  
  
  const colorhandleClickNew = () => {
    let toolError = "";
    let siteError="";
    let qtyError="";

    if (!toolsite) {
        siteError = "Site is required";
    } 
    if (!toolselect) {
        toolError = "Tool is required";
      } 
      if (!toolqty) {
        qtyError = "Quantity is required";
      } 
    if (siteError || toolError ||qtyError) {
        setSiteError(siteError);
      setToolError(toolError);
      setqtyError(qtyError);
      return;
    }
    const req = {
      tool: toolselect,
      site:toolsite,
      qty:toolqty
    };
    callApi("POST", "site_toolinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          setToolSite("");
          setToolSelect("");
          setToolQty("");
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          setToolModal(true);
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const logout = () => {
    const confirmLogout = window.confirm("Are you sure you want to Logout ?");
    if (confirmLogout) {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
    }
  };

  useEffect(() => {
    getProjectDetails();
    console.log("selectEditValue:", selectEditValue);
  }, [selectEditValue.tool]);

  const [EdittoolDetails, setExpenseeditData] = useState({
    id: "",
    edittool: "",
    editsite: "",
    editqty: "",
});

  const ToolEditModalshow = (id: any) => {
    
    setToolselectmodal(true);
    console.log("Tool Id", id);
    let req = {
      toolId: id,
    };
    callApi("POST", "site_tool_editselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          console.log("Tool_idselect", response.responseJson.data);
          let res = response.responseJson.data[0];
          setExpenseeditData((prevState) => ({
            ...prevState,
            id: res.id,
            edittool: res.edittool,
            editsite: res.editsite,
            editqty: res.editqty,
          }));
        console.log("Edit",EdittoolDetails);
        console.log("Edit-------Edit.editsite:", EdittoolDetails.editsite);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("EdittoolDetails :", EdittoolDetails);
  }, [EdittoolDetails]);

  const Editonchange = (e: any) => {
    const { name, value } = e.target;
    if (name === "editsite") {
        setExpenseeditData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    if (name === "edittool") {
        setExpenseeditData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    if (name === "editqty") {
        setExpenseeditData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

  };

  const updatecolor = (id: any) => {
    let req = {
      toolId: id,
      toolname:EdittoolDetails.edittool,
      sitename:EdittoolDetails.editsite,
      qty:EdittoolDetails.editqty
    };
    console.log("req", req);
    
    callApi("POST", "site_tool_update.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Tool Updated Successfully!");
        getProjectDetails();
        setToolselectmodal(false);
        setToolEditvalue({id: "", tool: "",site:"",qty:""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };

  const deletecolor = (id: any) => {
    const confirmLogout = window.confirm("Are you sure you want to delete?");
    if (confirmLogout) {
    let req = {
      toolId:id,
    };
    
    callApi("POST", "site_tool_delete.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Tool Deleted Successfully!");
        getProjectDetails();
        setToolEditvalue({id: "", tool: "",site:"",qty:""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
  };
  const back = () => {
    navigate(`/dashboard`);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredData = state.ToolDetailstable.filter((item: any) =>
    Object.values(item).some((value) =>
      (value as string).toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const [AddToolModal, setAddToolModal] = useState(false);
  const AddToolshow = () => {
    setAddToolModal(true);
    setToolModal(false);

  }
  const [tool, setTool] = useState("");


  const AddtoolModalClose = () => {
    setAddToolModal(false);
    setToolModal(true);

  };

  const handleTOOLChange = (e: any) => {
    const { name, value } = e.target;
  
    if (name === "tool") {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setTool(value);
        if (value.length < 5 || value.length > 50) {
          setToolError('Tool name must be between 5 and 50 characters.');
        } else {
          setToolError('');
        }
      }
    }
  };


  const AddhandleClick = () => {
    let toolError = "";
    if (!tool) {
      toolError = "Tool is required";
    } 
    if (toolError) {
      setToolError(toolError);
      return;
    }
    const req = {
      tool: tool,
    };
    callApi("POST", "tool_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          getSiteengineer();
          setAddToolModal(false);
    setToolModal(true);
          setTool("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddhandleClickNew = () => {
    let toolError = "";
    if (!tool) {
      toolError = "Tool is required";
    } 
    if (toolError) {
      setToolError(toolError);
      return;
    }
    const req = {
      tool: tool,
    };
    callApi("POST", "tool_add.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Tool Added Successfully!");
          getProjectDetails();
          setAddToolModal(true);
          getSiteengineer();
          setTool("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { ToolDetailstable } = state;

  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faCircleLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading"> Tools</h1>

          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon printicon" onClick={logout} />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="2" md="2" className=""></Col>
          <Col lg="3" md="3" className=" mblviewbutton">
            <Button
              variant="outline-primary Toolsbutton newbtn mobviewbtn"
              onClick={handleToolshow}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Tool
            </Button>
          </Col>
          <Col lg="3" md="3" className=" mblviewbutton">
            <Button
              variant="outline-primary Toolsbutton newbtn mobviewbtn"
              onClick={ToolManegeEdit}
            >
              {/* <FontAwesomeIcon icon={faPlusCircle} className="icon" /> */}
               Manage Tool
            </Button>
          </Col>
          <Col lg="2" md="2" className="mblcolsearch">
        
<div className="form-field" >
<FontAwesomeIcon icon={faSearch} className="icon" />
<input type="text"
 placeholder="Search here"
 onChange={(e) => handleSearch(e.target.value)}
/>
</div>
          </Col>

        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="2" md="2" className=""></Col>

          <Col lg="8" md="8" className="">
            {filteredData.length > 0 &&
            <>
            <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important",textAlign:"center" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Tool</th>
                  <th>Site</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
 {filteredData.map((details: any, index: any) => (
             
                        <tr>
                          <td>{index + 1}</td>
                          <td>{details.tool}</td>
                          <td>{details.site}</td>
                          <td>{details.qty}</td>
                          <td>
                            <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                className="actionbutton"
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => ToolEditModalshow(details.id)}
                                >
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() =>deletecolor(details.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                ))}
              </tbody>
             
            </Table>
            </>
}
          </Col>
          <Col lg="2" md="2" className=""></Col>
        </Row>
      </div>

      <Modal show={ToolModal} onHide={toolModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Site Name*</label>
              <Form.Select
                              aria-label="Default select example"
                              name="sitename"
                              onChange={handleChange}
                              value={toolsite}
                              onFocus={() => setSiteError("")}
                            >
                              <option value="">Select Site</option>
                              {state.SiteOptionselect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.site}
                                >
                                  {categery.site}
                                </option>
                              ))}
                            </Form.Select>
              <div className="text-danger">{siteError}</div>
            
            </div>

            <div className="labeldiv">
             
              <label className="label">Tool* </label>
              <div className="flex">
              <Form.Select
                              aria-label="Default select example"
                              name="toolselect"
                              onChange={handleChange}
                              value={toolselect}
                              onFocus={() => setToolError("")}

                            >
                              <option value="">Select Tool</option>
                              {state.ToolOptionselect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.tool}
                                >
                                  {categery.tool}
                                </option>
                              ))}
                            </Form.Select>
                            {/* <Button
              variant="outline-primary subbutton"
              onClick={AddToolshow}
              style={{marginLeft:"5%"}}
            > */}
              <FontAwesomeIcon icon={faPlusCircle} className="icon plusicon1"  
              onClick={AddToolshow}
               />
            {/* </Button> */}
</div>

              <div className="text-danger">{toolError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Quantity*</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Quantity"
                name="toolqty"
                minLength={5}
                maxLength={100}
                required
                pattern="[0-9]*"
                onChange={handleChange}
                value={toolqty}
                onFocus={() => setqtyError("")}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
              />
              <div className="text-danger">{qtyError}</div>
            </div>

            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={colorhandleClick}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={colorhandleClickNew}
              >
                Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={colorselectmodal} onHide={handleselectEditValueClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
             
           <label className="label"> Site Name</label>
              <Form.Select
                              aria-label="Default select example"
                              name="editsite"
                              onChange={Editonchange}
                              onFocus={() => setSiteError("")}
                              value={EdittoolDetails.editsite}
                            >
                              <option value="">Select Site</option>
                              {state.SiteOptionselect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.site}
                                >
                                  {categery.site}
                                </option>
                              ))}
                            </Form.Select>
              <div className="text-danger">{siteError}</div>
            </div>

            <div className="labeldiv">
              <label className="label">Tool </label>
              <Form.Select
                              aria-label="Default select example"
                              name="edittool"
                              onChange={Editonchange}
                              onFocus={() => setToolError("")}
                              value={EdittoolDetails.edittool}
                            >
                              <option value="">Select Tool</option>
                              {state.ToolOptionselect.map((categery: any) => (
                                <option
                                  key={categery.id}
                                  value={categery.tool}
                                >
                                  {categery.tool}
                                </option>
                              ))}
                            </Form.Select>
              <div className="text-danger">{toolError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Quantity</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Quantity"
                name="editqty"
                minLength={5}
                maxLength={100}
                required
                onChange={Editonchange}
                value={EdittoolDetails.editqty}
                onKeyPress={(e) => {
                  const isNumber = /[0-9]/.test(e.key);
                  if (!isNumber) {
                    e.preventDefault();
                  }
                }}
                onFocus={() => setqtyError("")}
              />
              <div className="text-danger">{qtyError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatecolor(EdittoolDetails.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal show={AddToolModal} onHide={AddtoolModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Tool* </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Tool"
                name="tool"
                minLength={5}
                maxLength={50}
                value={tool}
                required
                onChange={handleTOOLChange}
                onFocus={() => setToolError("")}
                pattern="^[A-Za-z]+$"
              />
              <div className="text-danger">{toolError}</div>
            </div>

            <div className="savenew">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={AddhandleClick}
              >
                Save
              </button>
              <button
                className="btn btn-primary modalsubbutton"
                onClick={AddhandleClickNew}
              >
                 Save & New
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </main>
  );
};

export default ToolsManagement;
